import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

function View({data}) {
  const bboxRootRef = useRef(null);
  const history = useHistory();

  // Load the script only once
  useEffect(() => {
    const existingScript = document.getElementById('bbox-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://bbox.blackbaudhosting.com/webforms/bbox-min.js';
      script.id = 'bbox-script';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      const bboxScript = document.querySelector('script[src="https://bbox.blackbaudhosting.com/webforms/bbox-min.js"]');
      if (bboxScript) {
        window.location.reload();
      }
    });
  }, [history]);

  // Replace the bbox-root div when data.donationFormID changes
  useEffect(() => {
    const oldRoot = bboxRootRef.current;
    if (oldRoot) {
      const newRoot = document.createElement('div');
      newRoot.id = 'bbox-root';
      oldRoot.replaceWith(newRoot);
      bboxRootRef.current = newRoot;

      window.bboxInit = function () {
        bbox.showForm(data.donationFormID);
      };
      if (window.bbox) {
        window.bboxInit();
      }
    }
  }, [data.donationFormID]);

  return <div id="bbox-root" ref={bboxRootRef} />;
}

export default View;
